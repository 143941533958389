<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="项目名称">
              <a-select v-model="queryParam.projectId" @change="projectChange">
                <a-select-option v-for="(data, key) of allProject" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="任务名称">
              <a-select v-model="queryParam.taskId" @change="fileNameChange">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(data, key) of allTask" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="外呼号码">
              <a-input :maxLength="300" v-model="queryParam.phones" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="接听时间">
              <a-range-picker v-model="date" @change="onChange" />
            </a-form-item>
          </a-col>
          <!--          <a-col :md="8" :sm="24">-->
          <!--            <a-form-item label="通话时长">-->
          <!--              <a-input-group compact>-->
          <!--                <a-input style=" width: 100px; text-align: center" placeholder="最短时间" v-model="queryParam.minTime"/>-->
          <!--                <a-input-->
          <!--                  style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"-->
          <!--                  placeholder="~"-->
          <!--                  disabled-->
          <!--                />-->
          <!--                <a-input style="width: 100px; text-align: center; border-left: 0" placeholder="最长时间" v-model="queryParam.maxTime"/>-->
          <!--                <a-input-->
          <!--                  style=" width: 40px; border-left: 0; pointer-events: none; backgroundColor: #fff"-->
          <!--                  placeholder="秒"-->
          <!--                  disabled-->
          <!--                />-->
          <!--              </a-input-group>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :md="8" :sm="24">
            <a-form-item label="呼叫状态">
              <a-select v-model="queryParam.callStatus">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="已接通">已接通</a-select-option>
                <a-select-option value="未接听">未接听</a-select-option>
                <!--                <a-select-option value="坐席无人接听">坐席无人接听</a-select-option>-->
                <!--                <a-select-option value="无可用坐席">无可用坐席</a-select-option>-->
                <!--                <a-select-option value="发起人取消">发起人取消</a-select-option>-->
                <a-select-option value="其他">其他</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="外呼小组">
              <a-select v-model="queryParam.organizationId" @change="organization">
                <a-select-option v-for="(data, key) of allOrganization" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="座席人员">
              <a-select v-model="queryParam.userId">
                <a-select-option v-for="(data, key) of AllUser" :key="key" :value="data.id">{{ data.realName }}</a-select-option>
                <a-select-option :value="-1">无座席</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" :loading="downLoad" icon="vertical-align-bottom" @click="exportClick" v-if="$checkPermission('Action:CallManage:Export')">导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :dataSource="data" rowKey="id" :loading="loading" :pagination="pagination" @change="pageChange">
      <!--      <span slot="time" slot-scope="time">-->
      <!--        {{ time | dateFormat }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text">
        <a-button type="primary" size="small" @click="playRadio(text)">
          播放录音
        </a-button>
        <a-divider type="vertical" />
        <a-button type="danger" size="small">
          <a :href="text.record" download="">下载录音</a>
        </a-button>
      </span>
    </a-table>
    <a-modal title="播放录音" :visible="visible" :footer="null" :closable="false" :centered="true" :destroyOnClose="true">
      <div class="dia-con">
        <audio :src="audioUrl" controls loop autoplay></audio>
        <p>
          <a-button @click="close">关闭</a-button>
        </p>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { findCallRecord, exportZip } from '@/api/outbound/call'
import { findAllProject, findAllOrganization, findAllExplicit, findAllTemplate, findAllTask, findAllUserList } from '@/api/findAll'
import moment from 'moment'
import fileDownload from 'js-file-download'

export default {
  name: 'CallView',
  data() {
    return {
      showEditModal: false,
      editData: {},
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50']
      },
      loading: false,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        projectId: null,
        taskId: null,
        phones: null,
        minTime: null,
        maxTime: null,
        startTime: null,
        endTime: null,
        callStatus: null,
        organizationId: null,
        userId: null,
        call: true
      },
      visible: false,
      audioUrl: '',
      data: [],
      downLoad: false,
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id'
        // },
        {
          title: '项目名称',
          dataIndex: 'projectName'
        },
        {
          title: '任务名称',
          dataIndex: 'taskName'
        },
        {
          title: '外呼号码',
          dataIndex: 'phone'
        },
        {
          title: '坐席人员',
          dataIndex: 'userName'
        },
        {
          title: '外呼状态',
          dataIndex: 'callStatus'
        },
        {
          title: '接听时间',
          dataIndex: 'answerTime'
        },
        {
          title: '挂断时间',
          dataIndex: 'hangupTime'
        },
        {
          title: '通话时长（秒）',
          dataIndex: 'talkTime'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      allProject: [],
      allOrganization: [],
      allExplicit: [],
      allTemplate: [],
      allTask: [],
      AllUser: [],
      date: [],
      fileName: '全部任务'
    }
  },
  filters: {
    dateFormat(date) {
      if (date) {
        let format = 'yyyy-MM-dd HH:mm:ss'
        date = new Date(date)
        date.setHours(date.getHours() - 14)
        const o = {
          'M+': date.getMonth() + 1, // month
          'd+': date.getDate(), // day
          'H+': date.getHours(), // hour
          'm+': date.getMinutes(), // minute
          's+': date.getSeconds(), // second
          'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
          S: date.getMilliseconds() // millisecond
        }
        if (/(y+)/.test(format)) {
          format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (const k in o) {
          if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
          }
        }
        return format
      }
    }
  },
  created() {
    this.findAll()
    this.query()
  },
  methods: {
    moment,
    async findAll() {
      await findAllProject()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allProject = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllOrganization()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allOrganization = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllExplicit()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allExplicit = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllTemplate()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTemplate = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllTask()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTask = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllUserList()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.AllUser = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    projectChange(value) {
      findAllTask({ projectId: value })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTask = res.body
          this.queryParam.taskId = null
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    organization(value) {
      findAllUserList({ organizationId: value })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.AllUser = res.body
          this.queryParam.userId = null
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    cancelHandle() {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle() {
      this.cancelHandle()
      this.queryClick()
    },
    playRadio(value) {
      this.audioUrl = value.record
      this.visible = true
    },
    addUserClick() {
      this.showEditModal = true
    },
    pageChange(pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    fileNameChange(value) {
      const index = this.allTask.findIndex(data => {
        return value === data.id
      })
      if (index === -1) {
        this.fileName = '全部任务'
      } else {
        this.fileName = this.allTask[index].name
      }
    },
    exportClick() {
      const data = { ...this.queryParam }
      delete data.size
      delete data.page
      if (data.phones) {
        data.phones = data.phones.split(',')
      }
      this.downLoad = true
      exportZip(data)
        .then(res => {
          // if (res.status !== 200 || res.status === 400) {
          //   throw new Error(res.message)
          // }
          fileDownload(res, `${this.fileName}.zip`)
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.downLoad = false
        })
    },
    query() {
      const data = { ...this.queryParam }
      if (data.phones) {
        data.phones = data.phones.split(',')
      }
      this.loading = true
      findCallRecord(data)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.body
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.dia-con {
  text-align: center;
}
.dia-con p {
  margin-top: 30px;
}
</style>
